<template>
  <div class="bgcontent">
    <Header style="background: none">
      <template slot="title">挂买挂卖记录</template>
    </Header>
    <div class="order">
      <div class="menubtn">
        <div class="btn">
          <div
            :class="type === 1 ? 'getbtn' : 'consumptionbtn'"
            @click="changeMenu(1)"
          >
            买入
          </div>
          <div
            :class="type === 2 ? 'getbtn' : 'consumptionbtn'"
            @click="changeMenu(2)"
          >
            卖出
          </div>
        </div>
      </div>
      <div class="buyandsell margin36">
        <van-tabs
          class="tab"
          v-model="activeTab"
          @change="changeTab"
          title-active-color="#27CC7F"
          title-inactive-color="#8BA695"
          background="#0C1913"
          color="#27CC7F"
        >
          <van-tab @change="changeTab" title="全部"></van-tab>
          <van-tab @change="changeTab" title="进行中"></van-tab>
          <van-tab @change="changeTab" title="完成"></van-tab>
          <van-tab @change="changeTab" title="撤单"></van-tab>
        </van-tabs>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="暂无数据"
          @load="onLoad"
        >
          <div class="cellcontent margin32">
            <div
              class="cell marginbottom24"
              v-for="item in logList"
              :key="item.id"
            >
              <div class="top">
                <div class="title van-ellipsis">{{ item.entrust_no }}</div>
                <div class="status">{{ getTransferTxt(item.status) }}</div>
              </div>
              <div class="money margin16">
                <div class="price">
                  <div class="num">{{ parseFloat(item.price).toFixed(3) }}</div>
                  <div class="pricetype margin8">订单价格(USD)</div>
                </div>
                <div class="price">
                  <div class="num">
                    {{ parseFloat(item.total_price).toFixed(2) }}
                  </div>
                  <div class="pricetype margin8">支付金额(CNY)</div>
                </div>
                <div class="price">
                  <div class="num">
                    {{ parseFloat(item.deals_remain_num).toFixed(2) }}
                  </div>
                  <div class="pricetype margin8">
                    订单数量：({{ item.stock_code }})
                  </div>
                </div>
              </div>
              <!-- <div class="card margin16">
                <div class="time">{{ item.pay_time }}</div>
                <div class="count">
                  支付倒计时:
                  <van-count-down
                    :time="getTimeDiff(item.timestamp)"
                    format=" mm 分 ss 秒"
                  />
                </div>
              </div> -->
              <div class="way margin24">
                <div class="method">
                  支付方式：
                  <div
                    class="payway"
                    v-for="pay in item.pay_type.split(',')"
                    :key="pay"
                  >
                    <img
                      v-if="pay === '1'"
                      class="payicon"
                      @click="clickViewPay('1', item)"
                      :src="require('@/assets/images/mine/usdt.png')"
                    />
                    <img
                      v-if="pay === '2'"
                      @click="clickViewPay('2', item)"
                      class="payicon"
                      :src="require('@/assets/images/mine/zhifubao.png')"
                    />
                    <img
                      v-if="pay === '3'"
                      @click="clickViewPay('3', item)"
                      class="payicon"
                      :src="require('@/assets/images/mine/card.png')"
                    />
                  </div>
                </div>
                <div class="cancelBtn" v-if="item.status == 1">
                  <div class="btn" @click="clickOrder(item)">取消</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <Viewpay ref="viewpay"></Viewpay>
    <van-dialog
      v-model="showpwd"
      title="请输入安全码"
      @cancel="showpwd = false"
      @confirm="confirm"
      show-cancel-button
    >
      <van-field
        type="password"
        v-model="password"
        name="安全密码"
        label="安全密码"
        placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import { myHangLog, cancleOrder } from "@/request/api";
import Header from "../../components/header.vue";
import { Toast } from "vant";
import Viewpay from "./modal/viewpay.vue";
export default {
  components: {
    Header,
    Viewpay,
  },
  computed: {
    getTransferTxt() {
      return (val) => {
        let txt = "";
        switch (val) {
          case 1:
            txt = "进行中";
            break;
          case 2:
            txt = "完成";
            break;
          case 3:
            txt = "撤单";
            break;
        }
        return txt;
      };
    },
  },
  data() {
    return {
      password: "",
      showpwd: false,
      currentpage: 1,
      refreshing: false,
      loading: false,
      finished: false,
      userInfo: {},
      type: 1,
      activeTab: 0,
      coin_symbol: "ATT",
      address: "",
      logList: [],
      tmpItem: {},
    };
  },
  activated() {
    this.coin_symbol = this.$route.query.coin;
    this.userInfo = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.address = sessionStorage.getItem("address");
    // this.address = "0x418c47eb99eb9b37b1d599c29d81633a5fdde517";
    console.log(this.address);
      if(this.address &&sessionStorage.getItem(this.address)){
          this.getOrderLog();
      }
  },
  methods: {
    getTimeDiff(comtime) {
      let sTime = new Date(comtime);
      let min = sTime.getMinutes();
      sTime = sTime.setMinutes(min + 30);
      let currenttime = new Date();
      let diff = parseInt(sTime - currenttime);
      console.log(diff);
      return diff;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.refreshing = true;
      this.currentPage = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
        if(this.address &&sessionStorage.getItem(this.address)){
            this.onLoad();
        }
    },
    onLoad() {
        if(this.address &&sessionStorage.getItem(this.address)){
            this.getOrderLog();
        }
      this.currentpage++;
    },
    clickViewPay(type, item) {
      //   this.$refs.viewpay.preview(type, item);
    },
    confirm() {
      if (this.password.length === 0) {
        Toast("请输入安全码");
        return;
      }
      const params = {
        address: this.address,
        pay_code: this.password,
        type: this.type,
        id: this.tmpItem.id,
      };
      cancleOrder(params).then((res) => {
        if (res.success) {
          Toast("取消成功");
          this.onRefresh();
        } else {
          Toast(res.msg);
        }
      });
    },
    clickOrder(item) {
      this.tmpItem = item;
      this.showpwd = true;
    },
    getOrderLog() {
        if(this.address &&sessionStorage.getItem(this.address)){
            const params = {
                coin_symbol: this.coin_symbol,
                type: this.type,
                status: this.activeTab,
                address: this.address,
                page: this.currentPage,
                limit: 10,
            };
            myHangLog(params).then((res) => {
                if (this.refreshing) {
                    this.refreshing = false;
                    this.logList = [];
                }
                if (res.success) {
                    this.logList = res.data.list;
                    if (res.data.list.length < 20) {
                        this.finished = true;
                    }
                } else {
                    Toast(res.msg);
                    this.finished = true;
                }
            });
        }
    },
    changeMenu(index) {
      this.type = index;
      this.getOrderLog();
    },
    changeTab() {
      console.log(this.activeTab);
      this.getOrderLog();
    },
    uploadPay(item) {
      console.log("上传支付");
      this.$refs.screenshot.showScreen(item);
    },
    clickApply(item) {
      this.$refs.complaint.showComplaint(item, this.type);
    },
    clickRelease(item) {
      const params = {
        id: item.id,
        pay_code: this.userInfo.pay_code,
        address: this.$store.state.user.userInfo.address,
      };
      confirmRelease(params).then((res) => {
        if (res.success) {
          Toast("操作成功");
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.margin36 {
  margin-top: 36px;
}
.margin32 {
  margin-top: 32px;
}
.margin16 {
  margin-top: 16px;
}
.margin8 {
  margin-top: 8px;
}

.margin24 {
  margin-top: 24px;
}

.marginbottom24 {
  margin-bottom: 24px;
}
.bgcontent {
  background: rgba(13, 25, 18, 1);
  min-height: 100vh;
  .order {
    padding: 32px;
    .menubtn {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        width: 320px;
        height: 72px;
        background: rgba(32, 40, 35, 1);
        border-radius: 24px;
        .getbtn {
          width: 160px;
          height: 72px;
          border-radius: 24px;
          opacity: 1;
          background: rgba(39, 204, 127, 1);
          color: rgba(13, 25, 18, 1);
          font-size: 32px;
          font-weight: 700;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 72px;
        }
        .consumptionbtn {
          width: 160px;
          height: 72px;
          border-radius: 24px;
          opacity: 1;
          color: rgba(182, 217, 195, 1);
          font-size: 32px;
          font-weight: 700;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 72px;
        }
      }
    }
    .cellcontent {
      .cell {
        width: 686px;
        height: 276px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(32, 40, 35, 1);
        padding: 24px;
        .top {
          display: flex;
          justify-content: space-between;
          .title {
            color: rgba(182, 217, 195, 1);
            font-size: 32px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 44px;
          }
          .status {
            width: 112px;
            height: 44px;
            border-radius: 16px 0 0 16px;
            background: rgba(39, 199, 204, 0.2);
            color: rgba(39, 199, 204, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 44px;
          }
        }
        .money {
          display: flex;
          justify-content: space-between;
          .price {
            .num {
              opacity: 1;
              color: rgba(214, 255, 227, 1);
              font-size: 44px;
              font-weight: 500;
              font-family: "DIN";
            }
            .pricetype {
              color: rgba(139, 166, 149, 1);
              font-size: 24px;
              font-weight: 400;
              font-family: "PingFang SC";
            }
          }
        }
        .card {
          width: 638px;
          height: 64px;
          border-radius: 16px;
          opacity: 1;
          background: rgba(41, 51, 45, 1);
          display: flex;
          justify-content: space-between;
          padding: 0 16px;
          .time {
            opacity: 1;
            color: rgba(139, 166, 149, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            line-height: 64px;
          }
          .count {
            opacity: 1;
            color: rgba(139, 166, 149, 1);
            line-height: 64px;
            font-weight: 400;
            font-family: "PingFang SC";
            display: flex;
            ::v-deep .van-count-down {
              color: rgba(139, 166, 149, 1);
              font-size: 12px;
              line-height: 32px;
            }
          }
        }
        .way {
          color: rgba(139, 166, 149, 1);
          font-size: 28px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .method {
            display: flex;
            align-items: center;
            .payway {
              display: flex;
              align-items: center;
              .payicon {
                margin-right: 10px;
                width: 32px;
                height: 32px;
              }
            }
          }
          .operation {
            display: flex;
            align-items: center;
            .uploadicon {
              width: 32px;
              height: 32px;
            }
            .operate {
              color: rgba(39, 204, 127, 1);
              font-size: 28px;
              font-weight: 700;
              font-family: "PingFang SC";
            }
          }
        }
      }
    }
  }
}
</style>